
import Produkty from '../../components/Produkty/Produkty';

import { produkty } from './produktyData';

const ProduktyPage = () => {
  return (
    <>
      <Produkty produkty={produkty} />
    </>
  );
};

export default ProduktyPage;
