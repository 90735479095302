import React from 'react';
import { Link } from 'react-router-dom';

import './produkty.css';

interface IProdukty {
  shortDesc: string;
  link: string;
  image: string;
  title: string;
  description: string;
}

interface HomeProps {
  produkty: IProdukty[];
}

const Produkty: React.FC<HomeProps> = ({ produkty }) => {
  return (
    <div className='be-home-container'>
      <div className='be-products-container'>
        <p className='be-products-header'>PRODUKTY</p>
        <div className='be-products'>
          {produkty.map((singleProduct: IProdukty) => (
            <div className='be-single-products'>
              <Link to={`/produkty/${singleProduct.link}`} className='be-single-products-hiperlink'>
              <div className='be-single-products-image-container'>
                <img alt={singleProduct.title} src={singleProduct.image} className='be-single-products-image' />
              </div>
              <p className='be-single-products-title'>
                <h2>{singleProduct.title}</h2>
              </p>
              <p className='be-single-products-description'>{singleProduct.shortDesc.split(':').map((product) => <><p>{product}</p><br/></>)}</p>
                Zobacz szczegóły
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Produkty;
