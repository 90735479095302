
import { useParams } from 'react-router-dom';

import Produkt from '../../components/Produkty/Produkt';

import { produkty } from './produktyData';

const ProduktPage = () => {
  const params = useParams();
  const produkt = produkty.find((product) => product.link === params.link);

  return produkt !== undefined ? (
    <>
      <Produkt produkt={produkt} />
    </>
  ) : (
    <>404 Not Found</>
  );
};

export default ProduktPage;
