import { HashRouter, Route, Routes } from 'react-router-dom';

import Footer from './components/Footer/Footer';
import Menu from './components/Menu/Menu';
// import BranzePage from './pages/Branze/BranzePage';
import Homepage from './pages/Homepage/Homepage';
import KonsultingPage from './pages/Konsulting/KonsultingPage';
import ProduktPage from './pages/Produkty/ProduktPage';
import ProduktyPage from './pages/Produkty/ProduktyPage';

const App = () => {
  return (
    <HashRouter>
      <Menu />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/produkty' element={<ProduktyPage />} />
        <Route path='/produkty/:link' element={<ProduktPage />} />
        <Route path='/konsulting' element={<KonsultingPage />} />
        {/* <Route path='/branze' element={<BranzePage />} /> */}
        <Route path='*' element={<div>404 not found</div>} />
      </Routes>
      <Footer />
    </HashRouter>
  );
};

export default App;
