import './home.css';

interface Project {
  description: string;
}

interface Owner {
  image: string;
  description: string;
}

interface HomeProps {
  projects: Project[];
  owner: Owner;
  coOwner: Owner;
}

const Home: React.FC<HomeProps> = ({ projects, owner, coOwner }) => {
  return (
    <div className='be-home-container'>
      <div className='be-owner-container'>
        <div>
          <img className='be-owner-photo' src={owner.image} alt='ownerPhoto'></img>
        </div>
        <div className='be-owner-description'>
          <p>{owner.description}</p>
        </div>
      </div>
      <div className='be-coowner-container'>
        <div>
          <img className='be-owner-photo' src={coOwner.image} alt='ownerPhoto'></img>
        </div>
        <div className='be-coowner-description'>
          <p>{coOwner.description}</p>
        </div>
      </div>
      <div className='be-projects-container'>
        <div className='be-projects'>
          {projects.map((project, index) => (
            <div key={`be-project-${index}`} className='be-project'>
              <div className='be-project-info'>
                <p className='be-project-description'>{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
