import './footer.css';

const Footer = () => {
  return (
    <div className='be-footer'>
      <div className="be-get-in-touch">
        <p>ZOSTAŃMY W KONTAKCIE:</p>
        <div className="be-company-data">
          <p>Tel: <a href='tel:535788221'>535 788 221</a></p>
          <p>ul. Sacharowa 69 lok 19</p>
          <p>Email: <a href='mailto:jk@jaroslawkubacki.pl'>jk@jaroslawkubacki.pl</a></p>
          <p>92-518 Łódź</p>
        </div>
        <div className="be-social-media">
          <a href='https://www.linkedin.com/in/jaros%C5%82aw-kubacki-a132b4a9/' target={'_blank'} rel="noreferrer"><i className="fab fa-linkedin fa-2x"></i></a>
        </div>
        <p className='be-copyright'>© 2023 by Jarosław Kubacki.</p>
      </div>
    </div>
  );
};

export default Footer;
