import { Link, NavLink } from 'react-router-dom';

import logo from '../../images/logo.png';

import './menu.css';

const Menu = () => {
  return (
    <div className='be-menu-container'>
      <Link to={'/'}>
        <img src={logo} style={{ height: '100px' }} alt='Jarosław Kubacki' />
      </Link>
      <div className='be-menu-links'>
        <NavLink to="/" className={`be-menu-link ${window.location?.pathname === '/' ? 'active' : ''}`}>
          O NAS
        </NavLink>
        <NavLink to="/produkty" className={(isActive) => "be-menu-link" + (!isActive ? " active" : "")}>
          PRODUKTY
        </NavLink>
        <NavLink to="/konsulting" className={(isActive) => "be-menu-link" + (!isActive ? " active" : "")}>
          KONSULTING
        </NavLink>
        {/* <NavLink to="/branze" className={(isActive) => "be-menu-link" + (!isActive ? " active" : "")}>
          BRANŻE
        </NavLink> */}
      </div>
    </div>);
};

export default Menu;
