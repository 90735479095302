import { Carousel } from 'react-responsive-carousel';

import './imageCarousel.css';

interface ImageData {
  img: string;
  text: string;
}

interface ImageCarouselProps {
  images: ImageData[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {

  return (
    <div className='be-imageCarousel'>
      <Carousel
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={4000}
        transitionTime={1500}>
        {images.map((image) => (
          <div>
            <img className='be-imageCarousel-image' alt='' src={image.img} />
          </div>
        ))}
      </Carousel>
      <div className='be-imageCarousel-overflow-container'>
        <div>
          <p>Consulting</p>
        </div>
        <div className='be-imageCarousel-overflow-subtext'>
          <p>Efektywność</p>
          <p>Skuteczność</p>
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
