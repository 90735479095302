import slider1 from '../../images/slider-1.jpg';
import slider2 from '../../images/slider-2.jpg';
import slider3 from '../../images/slider-3.jpg';
import ownerPhoto from '../../images/zdjecie-jarek.jpg';
import ladyPhoto from '../../images/zdjecie-pani.jpg';

const sliderImages = [
  { img: slider1, text: 'Nowoczesna architektura' },
  { img: slider2, text: '' },
  { img: slider3, text: 'Wyjątkowe budynki' }
];

const projects = [
  {
    description:
      'W Jarosław Kubacki Consulting zajmujemy się szeroko pojętym doradztwem biznesowym. Jesteśmy zespółem ekspertów: trenerów i konsultantów biznesowych, wspierających wzrost efektywności pracy jednostek, zespołów i organizacji. W zakres naszych usług wchodzą szkolenia, doradztwo dla firm oraz coaching biznesowy.  '
  },
  {
    description:
      'Wyróżnia nas precyzja w systemowym postrzeganiu organizacji biznesowych. Dwudziestoletnie doświadczenie w restrukturyzacji przedsiębiorstw pozwala nam na efektywne zaprojektowanie rozwoju firmy. Budujemy skuteczne strategie opierające się na jak najlepszym ułożeniu struktur organizacyjnych. Nasze doradztwo opiera się na synergetycznej pracy klientami, a naszym celem jest, aby wdrożone rozwiązania, były w całości spójne z wizją prowadzenia przedsiębiorstwa.'
  },
  {
    description:
      'Dążąc do stałego rozwoju osobistego oraz profesjonalnego naszych interesariuszy, oferujemy im szereg szkoleń dedykowanych, jak również coaching biznesowy. Szczególną wagę przykładamy do rozwoju procesów komunikacji pomiędzy poszczególnymi elementami struktury organizacyjnej przedsiębiorstwa. Każde szkolenie jest poprzedzone identyfikacją potrzeb organizacji. Poprzez coaching biznesowy wpieramy naszych klientów w podnoszeniu ich samoświadomości i umiejętności, pomagając osiągnąć im w biznesie sukces i satysfakcję z tego co robią.'
  }
];

const owner = {
  image: ownerPhoto,
  description: `Jarosław Kubacki -
  Od 20 lat specjalizuje się w opracowywaniu i
  wdrażaniu systemów zarządzania.
  Budowanie struktur organizacji, zakresów
  obowiązków pracowniczych, mapowanie
  procesów, opracowanie i wdrażanie procedur
  systemowych.
  Certyfikowany Pełnomocnik ds. Systemów
  Zarządzania Jakością przez Polskie Centrum
  Badań i Certyfikacji. Ponadto Manager
  Systemu Zarządzania Ciągłością Działania.
  Absolwent wydziału Prawa i Administracji
  Uniwersytetu Łódzkiego oraz Canadian
  International Management Institute Harvard
  Business School – program Management.
  Tworzył i wdrażał strategie zarządzania dla
  firm z branży produkcyjnej, handlowej oraz
  usługowej. W ramach interim managementu
  pełnił funkcję Dyrektora Operacyjnego w
  kilku łódzkich przedsiębiorstwach.`
};

const coOwner = {
  image: ladyPhoto,
  description: `Jolanta Cyran - Koordynator projektów doradczych.
  Uczestniczy w opracowywaniu i wdrażaniu
  systemów zarządzania, w tym: budowaniu
  struktur organizacji, tworzeniu zakresów
  obowiązków pracowniczych, mapowaniu
  procesów, opracowaniu i wdrażanie procedur
  systemowych.
  Swoje 5-letnie doświadczenie jako manager
  w placówce całodobowej opieki i rehabilitacji
  wykorzystuje w postaci wiedzy merytorycznej
  przy wdrażaniu nowo opracowanych
  standardów do firm.
  Absolwentka wydziału Prawa i Administracji
  Uniwersytetu Łódzkiego - kierunek Prawo
  Podatkowe i Rachunkowość.
  Swoje kompetencje doskonali na studiach
  podyplomowych - kierunek Zarządzanie
  Organizacjami Ochrony Zdrowia w Szkole
  Głównej Handlowej w Warszawie.`
};

export { sliderImages, projects, owner, coOwner };
