const konsultingData = {
  title: 'Konsulting',
  description: `W procesie doradztwa, szczególną̨ wagę przykładamy do standaryzacji pracy. Skuteczne i efektywne funkcjonowanie każdej organizacji biznesowej w swoich podwalinach posiada zoptymalizowane procesy zarządzania. Procesy właściwie zmapowane, opisane i wdrożone.
  <br>Takie podejście daje gwarancję zachowania ciągłości działania firmy oraz pozwala zminimalizować ryzyko w obszarze zarządzania, szczególnie na poziomie operacyjnym i wykonawczym. Dobrze wdrożony system jest gwarantem skutecznego kaskadowania celów postawionych przez Właściciela.
  Swoją skuteczność i efektywność zawdzięczamy optymalnie wyselekcjonowanym narzędziom biznesowym, indywidualnie dostosowanym do potrzeb danej organizacji. Każda usługa projektowana jest przy bardzo bliskiej współpracy z naszymi Klientami. Takie podejście generuje efekt synergii, który bezpośrednio przekłada się na jakość realizowanych przez nas projektów.
  <br>Realizacja każdego projektu poprzedzona jest bezpłatnym audytem wstępnym, który pozwala zdefiniować potencjał do doskonalenia firmy.
  <br>Nasze działania zorientowane są na osiągnięcie najwyższego poziomu jakości świadczonych usług oraz wypracowanie realnego zwrotu z inwestycji przez klienta w ramach współpracy z nami.
  `,
};

export { konsultingData };
