import Home from '../../components/Home/Home';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';

import { coOwner, owner, projects, sliderImages } from './homeData';

const HomePage = () => {
  return (
    <>
      <ImageCarousel images={sliderImages} />
      <Home projects={projects} coOwner={coOwner} owner={owner} />
    </>
  );
};

export default HomePage;
