import React from 'react';

import Konsulting from '../../components/Konsulting/Konsulting';

import { konsultingData } from './konsultingData';

const KonsultingPage = () => {
  return (
    <>
      <Konsulting konsultingData={konsultingData} />
    </>
  );
};

export default KonsultingPage;
