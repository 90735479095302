import React from 'react';

interface IProdukty {
  link: string;
  image: string;
  title: string;
  description: string;
}

interface IProduktProps {
  produkt: IProdukty;
}

const Produkt = ({ produkt }: IProduktProps) => {
  console.log(produkt);
  return (
    <>
      <div className='be-produkt-background-image-container'>
        <img src={produkt.image} alt={produkt.link} className='be-produkt-background-image' />
      </div>
      <div className='be-produkt-container'>
        <h1 className='be-produkt-title'>{produkt.title}</h1>
        <div className='be-produkt-description'>
          {produkt.description.split('<br>').map((part) => (
            <p>{part}</p>
          ))}
        </div>
      </div>
    </>
  );
};

export default Produkt;
