import React from 'react';

import './konsulting.css';

interface IKonsultingProps {
  konsultingData: {
    title: string;
    description: string;
  };
}

const Konsulting = ({ konsultingData }: IKonsultingProps) => {
  return (
    <div className='be-konsulting-container'>
      <h1 className='be-konsulting-title'>{konsultingData.title}</h1>
      <div className='be-konsulting-description'>
        {konsultingData.description.split('<br>').map((part) => (
          <p>{part}</p>
        ))}
      </div>
    </div>
  );
};

export default Konsulting;
